html,
body {
  &::-webkit-scrollbar {
    display: none;
  }
}

body {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

// Global variables here
$background-color: #F9F9F9;
$white: #FFFFFF;
$border-color: #E0E0E0;

.App {
  background-color: $background-color;
  overflow: hidden;
  height: calc(100vh);
}

@import "./typography";
@import "./common";