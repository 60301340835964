.overview_ssohome{
    width: 50% ;
    display:block ;
    align-items: center;
    margin: auto;
    text-align: center;
    background-color: #2931b5;
    border-radius: 8px;
}
.sl-bubble5{
    position: absolute;
    top: 50%;
    left:46%
} 