.landing{
    height:100%;
    margin:0 auto
}
/* .nav-container
{
    padding: 10px 10%;
    background-color: #F8F9FE;
    border-bottom: 0;
} */
.landing_page
{
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    height: calc(100% - 68px);
    background-color: #F8F9FE;
}
.login-btn
{
    padding: 7px 24px;
    background: #332FF1 !important;
    border-radius: 8px;
    color: #fff;
    margin: 0;
    border: 0;
}
.overview_mainSection_image
{
    background-image: url('../../assets/images/landing.png');
    background-position: right bottom;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 89%;
    position: absolute;
    z-index: 8;
}
.overview_mainSection_landing {
    margin: auto;
    width: 50%;
    padding: 10% 5%;
    box-sizing: border-box;
    /* max-width: 410px; */
    position: absolute;
    z-index: 9;
}
.overview_mainSection_landing h1
{
    font-size: 60px;
}
.overview_mainSection_landing h2
{
    font-size: 20px;
}
.overview_mainSection_landing p
{
    font-size: 20px;
}