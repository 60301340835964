.dashboard {
  text {
    text-anchor: middle;
    alignment-baseline: central;
  }

  .rv-xy-plot__axis__line {
    stroke-width: 1px;
    stroke: rgb(224, 224, 224);
}
  
  .rv-discrete-color-legend-item, text {
    font-family: Manrope;
  }
  
  .rv-xy-plot__grid-lines__line {
    stroke-dasharray: 5, 5;
  }

  .rv-discrete-color-legend-item__color {
    height: 14px;

    path {
      stroke-width: 14px;
    }
  }

  .rv-discrete-color-legend.horizontal {
    display: flex;
    flex-wrap: wrap;
  }
  
  .cohort-status__number text {
    font-size: 40px;
    fill: black;
    font-family: Manrope-Semibold;
    text-anchor: middle;
  }
  
  .cohort-status__learner text {
    font-size: 18px;
    fill: black;
    font-family: Manrope-Medium;
    text-anchor: middle;
  }

  .heatmap .rv-xy-plot__axis.rv-xy-plot__axis--vertical text {
    alignment-baseline: baseline;
  }

  .flex-scores, .core-scores {
    &-label {
      font-size: 11px;
    }
  }
}