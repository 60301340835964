/* css for header and drawer secion */
.departmentHeading {
  padding-left: 2%;
  font-size: 16px;
  font-family: Manrope-Medium;
}
.notificationPopup {
  position: absolute;
  right: 122px;
  top: 34px;
  width: 450px;
  height: 350px;
  border-radius: 8px;
}
.notifictionHeading {
  font-family: "Manrope-Bold";
  padding: 14px 24px 0px 24px;
  font-size: 18px;
  line-height: 25px;
  color: #3d3d3d;
}
.notificationsInnerContent {
  display: flex;
  padding: 0px 24px;
}
.postedTime {
  margin-left: 20%;
  font-family: "Manrope-Semibold";
  font-size: 12px;
  line-height: 19px;
  color: #858585;
}
.notifUserName {
  font-family: "manrope-Bold";
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: #3a3fdf;
  text-transform: capitalize;
}
.notifUserStatus {
  font-family: "manrope-Bold";
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: #000000;
}
.chatIconStyle {
  margin-right: 8px;
}
.parentWithSubMenu {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin: 6px;
  height: 48px;
  align-items: center;
}
.parentWithoutSubMenu {
  border-radius: 4px;
  margin: 6px;
}
.k-drawer-item.k-state-selected,
.k-drawer-item.k-state-selected:hover {
  color: #3d3d3d;
  background-color: #f8f8ff;
}
#chevronArrowSidebar {
  position: absolute;
  right: 10px;
}
#menuIconBtn {
  font-size: 24px;
  margin-bottom: 10px;
}
.collapseIcon {
  width: 20px;
  height: 18px;
  right: 5px;
}
.expandIcon {
  position: absolute;
  right: 0px;
}
.upload-dialog {
  margin: 1.5rem;
}
.k-upload {
  min-height: 100px;
}
.uploadImageDailog .k-dialog {
  width: 730px;
  height: fit-content;
}
.k-upload {
  min-height: 100px;
}
.mentorUsernameText {
  text-transform: capitalize;
}
.successMsg {
  padding-bottom: 0;
  margin-bottom: 0;
  text-align: center;
  color: green;
  border-radius: 4px;

}
.uploadedImage {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  border: 1px solid #333;
  vertical-align: middle;
  margin-right: 20px;
}
.upload_text {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #332ff0;
  cursor: pointer;
}
.imageSec {
  display: "none";
}
.imageDispaly {
  margin-bottom: 20px;
}

.drawer-content {
  flex: 1 1 auto;
  height: calc(100vh - 68px);
  overflow: auto;
  margin-left: 50px;
}
 .profile-section .sl-bubble5{
  top: 13% !important;
    left: 29% !important;
    z-index: 99999 !important;
} 