@font-face {
    font-family: "Roboto";
    src: url("fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Epilogue-Semibold";
    src: url("fonts/Epilogue-SemiBold.ttf") format("truetype");
}
@font-face {
    font-family: "Epilogue-Medium";
    src: url("fonts/Epilogue-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "Epilogue";
    src: url("fonts/Epilogue-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Manrope-Bold";
    src: url("fonts/Manrope-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "Manrope-SemiBold";
    src: url("fonts/Manrope-SemiBold.ttf") format("truetype");
}
@font-face {
    font-family: "Manrope-Medium";
    src: url("fonts/Manrope-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "Manrope";
    src: url("fonts/Manrope-Regular.ttf") format("truetype");
}

body {
    font-family: "Roboto", sans-serif;
}

h1 {
    font-size: 1.25rem;
    font-weight: 400;
}
h2 {
    font-size: 1.0rem;;
    font-weight: 400;
}
h3 {
    font-size: 1.5rem;
    font-weight: 400;
}
h4 {
    font-size: 1.25rem;
    font-weight: 400;
}
h5 {
    font-size: 1.25rem;
    font-weight: 400;
}
p {
    font-size: 1rem;
}
a {
    font-size: 1rem;
}