// Normalization
body,
h1,
h2,
h3 {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}

// App styles
// .card-container {
//   background-color: $white;
//   padding: 40px 0;
//   text-align: center;

//   &.grid {
//     display: grid;
//     column-gap: 10px;
//     row-gap: 1em;
//   }
// }

// .card-ranges {
//   .k-daterangepicker {
//     .k-textbox-container,
//     .k-floating-label-container {
//       padding-top: 0;
//       min-width: 105px;
//     }

//     .k-label {
//       display: none;
//     }
//   }
// }

// .card-component {
//   grid-column: 1 / -1;
//   overflow: hidden;
// }

// @media (min-width: 480px) {
//   .card-container {
//     text-align: initial;
//     padding: 30px;

//     .card-title {
//       grid-column: 1 / 2;
//       grid-row: 1;
//     }

//     .card-buttons {
//       text-align: center;
//       grid-column: 1 / -1;
//       grid-row: 2;
//     }

//     .card-ranges {
//       text-align: right;
//       grid-column: 2 / 3;
//       grid-row: 1;
//     }
//   }
// }

/* Common Css For components */
// .k-breadcrumb {
//   background-color: #e5e5e5;
// }
// .k-breadcrumb-link,
// .k-breadcrumb-root-link {
//   color: #000000;
//   font-size: 12px;
//   font-family: Manrope-SemiBold;
// }
// .k-panelbar {
//   border: none;
// }
// .k-panelbar .k-panelbar-header .k-link,
// .k-panelbar .k-panelbar-group .k-panelbar-item .k-link,
// .k-panelbar .k-group > .k-item > .k-link {
//   padding: 0;
// }
// .k-panelbar > .k-item > .k-link.k-state-selected,
// .k-panelbar > .k-panelbar-header > .k-link.k-state-selected {
//   background-color: transparent;
//   color: black;
// }
// .k-panelbar .k-group > .k-item.k-level-1 .k-link,
// .k-panelbar .k-panelbar-group > .k-panelbar-item.k-level-1 .k-link {
//   padding-left: 0;
// }
// .k-panelbar > .k-item > .k-link.k-state-selected:hover,
// .k-panelbar > .k-item > .k-link.k-state-selected.k-state-focus,
// .k-panelbar > .k-item > .k-link.k-state-selected.k-state-focused,
// .k-panelbar > .k-panelbar-header > .k-link.k-state-selected:focus {
//   background-color: transparent;
// }
// .k-panelbar > .k-item > .k-link,
// .k-panelbar > .k-panelbar-header > .k-link {
//   color: #000000;
//   font-size: 18px;
//   padding-bottom: 10px;
// }
// .k-panelbar > .k-panelbar-header > .k-link:hover,
// .k-panelbar > .k-item > .k-link:hover {
//   background-color: transparent;
// }
// .k-icon.k-i-zoom.search {
//   border: 1px solid #e0e0e0;
//   padding: 10px;
//   border-right: 0;
//   right: 0;
//   color: #252525;
//   border-radius: 4px 0 0 4px;
// }
.search_input,
.search_input:focus,
.search_input:focus-visible {
  height: 32px;
  border-radius: 0 4px 4px 0;
  border-left: none;
  font-family: Manrope-Medium;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  outline: none;
  padding-bottom: 3px;
}
// .k-input-value-text {
//   font-family: Manrope-Medium;
// }
// .overview_mainSection {
//   margin: auto;
//   width: 100%;
//   overflow: auto;
//   height: calc(100vh - 68px);
// }
// .subSection {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin: 0px 40px;
// }
// .overview_heading {
//   text-align: left;
//   width: 100%;
//   padding: 22px 0;
//   font-size: 36px;
//   font-weight: 600;
//   color: #252525;
//   font-family: Epilogue;
// }
// .downloadBtn {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 193px;
//   height: 44px;
//   gap: 16px;
//   top: 0px;
//   background: #ffffff;
//   border: 1px solid #41407b;
//   box-sizing: border-box;
//   border-radius: 8px;
//   cursor: pointer;
// }
// .downloadIcon {
//   color: #41407b;
// }
// .downloadDataText {
//   font-family: Manrope-Bold;
//   font-size: 16px;
//   font-weight: 700;
//   color: #41407b;
// }
// .searchIcon {
//   margin: 6px 0px;
// }
// .tableOuterDiv {
//   border: 1px solid rgba(103, 120, 138, 0.1);
//   border-radius: 8px;
// }
// #contentCell {
//   margin: 0px 7px;
//   display: flex;
// }
// .progress-section {
//   position: relative;
// }
// .overlay-progress {
//   position: absolute;
//   left: 0;
//   top: 0;
//   opacity: 1;
//   width: 100%;
// }
// .overlay-progress .progressBar {
//   background: transparent;
// }
// .lessonDiv {
//   border: 1px solid #e0e0e0;
//   border-radius: 4px;
//   margin: 4px 0px;
//   width: 430px;
// }
// .lessonsInnerDiv {
//   width: 100%;
//   float: left;
//   display: grid;
//   grid-template-columns: 33% 33% 33%;
//   padding: 10px;
// }
// .trackProgressConatiner {
//   display: grid;
//   grid-template-columns: 74% 23%;
//   padding-bottom: 20px;
// }
// .leftCard {
//   background-color: #ffffff;
//   margin: 0px 20px 0 40px;
//   padding: 24px;
//   border-radius: 8px;
//   height: fit-content;
//   border: 1px solid $border-color;
// }
// .heading {
//   font-family: Manrope-Bold;
//   font-style: normal;
//   font-weight: 800;
//   font-size: 18px;
// }
// .searchInput {
//   margin: 30px 0px;
// }
// .at_risk.k-progressbar .k-state-selected {
//   background: #ecb0b0;
// }
// .iconHoldDiv {
//   margin: -10px -20px;
// }
// .dataNotAvailable {
//   font-family: Manrope-Medium;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 12px;
//   line-height: 16px;
//   display: flex;
//   margin: 20px 0px;
// }
// .studentProfileCard {
//   padding: 24px;
//   background: #ffffff;
//   border: 1px solid #e0e0e0;
//   box-sizing: border-box;
//   border-radius: 8px;
//   margin-bottom: 10px;
// }
// .profileHeading {
//   font-family: "Manrope-Medium";
//   font-style: normal;
//   font-weight: 800;
//   font-size: 18px;
//   line-height: 25px;
// }
// .profileImagewithText {
//   display: flex;
//   align-items: center;
//   flex-direction: row;
// }
// .profileImagewithText img {
//   display: inline-block;
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   margin: 10px 0px;
// }
// .usernameText {
//   font-size: 16px;
//   color: #3a3fdf;
//   display: block;
//   font-family: "Manrope-Bold";
// }
// .competency {
//   font-family: "Manrope-Medium";
//   font-style: normal;
//   font-weight: 800;
//   font-size: 14px;
//   line-height: 19px;
//   display: flex;
//   align-items: center;
//   margin: 16px 0px;
// }
// .chartDiv {
//   position: static;
//   width: 200px;
//   height: 130px;
//   top: 0px;
//   flex: none;
//   order: 0;
//   flex-grow: 0;
// }
// .scorePercentileContainer {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   margin: 10px 0px;
// }
// .mainText {
//   font-family: "Manrope-Bold";
//   font-size: 14px;
//   line-height: 19px;
//   display: block;
//   align-items: center;
// }
// .subText {
//   font-family: "Manrope-Medium";
//   font-size: 12px;
//   line-height: 16px;
//   align-items: center;
//   color: #666666;
// }
// .macroImg {
//   margin-left: -20px;
//   margin-top: -20px;
// }
// .credentileContainer {
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   margin: 10px 0px;
// }
// .credentialText {
//   font-family: "Manrope-Bold";
//   font-size: 14px;
//   line-height: 19px;
//   align-items: center;
//   position: relative;
//   top: -44px;
// }
// .credentialContentText {
//   font-family: "Manrope-Bold";
//   font-size: 14px;
//   line-height: 19px;
//   align-items: center;
//   position: relative;
//   top: 6px;
// }
// .regularImg {
//   margin-left: -20px;
//   margin-top: -30px;
// }
// .regularText {
//   font-family: "Manrope-Bold";
//   font-size: 14px;
//   line-height: 19px;
//   align-items: center;
//   position: relative;
//   top: -36px;
// }
// .regularContentText {
//   font-family: "Manrope-Bold";
//   font-size: 14px;
//   line-height: 19px;
//   align-items: center;
//   position: relative;
//   top: -8px;
// }
// .breadcrumb-navigation {
//   display: flex;
//   flex-direction: row;
// }
// .breadcrumb-InnernavigationRight {
//   display: grid;
//   margin-right: 3%;
// }
// .breadcrumb-InnernavigationLeft {
//   display: grid;
//   margin-left: 3%;
// }
// .breadcrumb-Innernavigation {
//   display: grid;
//   margin: 0px 3%;
// }
// .breadcrumb-mainHeading {
//   font-family: "Manrope-Medium";
//   font-size: 12px;
//   font-weight: 500;
//   line-height: 16px;
//   display: flex;
//   align-items: center;
//   color: #000000;
//   margin: 4px 0px;
// }
// .breadcrumb-subHeading {
//   font-family: "Manrope-Bold";
//   font-size: 18px;
//   line-height: 25px;
//   color: #3a3fdf;
//   width: max-content;
//   cursor: pointer;
// }
// .breadcrumb-subHeadingOther {
//   font-family: "Manrope-Bold";
//   font-size: 18px;
//   line-height: 25px;
//   color: #3d3d3d;
//   width: max-content;
// }
// .breadcumsIcon {
//   position: relative;
//   top: 15px;
//   font-size: x-large;
// }
// .tabletColoumDiv {
//   width: 700px;
//   display: grid;
//   padding: 10px 0px;
//   border-bottom: 1px solid rgba(103, 120, 138, 0.1);
//   grid-template-columns: 60% 20% 20%;
// }
// .tabletColoumDivLast {
//   width: 700px;
//   display: grid;
//   padding: 10px 0px;
//   grid-template-columns: 60% 20% 20%;
// }
/* End Common Css For components */

// @media (min-width: 768px) {
//   .card-container {
//     margin: 25px;
//     border-radius: 8px;
//     border: 1px solid $border-color;

//     .card-title {
//     }

//     .card-buttons {
//       text-align: center;
//       grid-column: 2 / 3;
//       grid-row: 1;
//     }

//     .card-ranges {
//       text-align: right;
//       grid-column: 3 / 4;
//     }

//     .card-component {
//       grid-column-start: 1;
//       grid-column-end: -1;
//     }
//   }

//   .card-ranges {
//     text-align: right;
//     grid-column: 2 / 3;
//     grid-row: 1;
//   }
// }

// @media (min-width: 768px) {
//   .card-container {
//     margin: 25px;
//     border-radius: 8px;
//     border: 1px solid $border-color;

//     // &.grid {
//     //     grid-template-columns: repeat(3, 1fr);
//     // }

//     .card-title {
//     }

//     .card-buttons {
//       text-align: center;
//       grid-column: 2 / 3;
//       grid-row: 1;
//     }

//     .card-ranges {
//       text-align: right;
//       grid-column: 3 / 4;
//     }

//     .card-component {
//       grid-column-start: 1;
//       grid-column-end: -1;
//     }
//   }
// }

// Header
.header {
  height: 80px;
  position: sticky;
  top: 0;
  z-index: 3;

  .nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    color: $white;
  }

  .menu-button {
    flex: 0 0 50px;
    text-align: center;
  }

  .hamburger-icon {
    background-image: url("../images/hamburger-icon.svg");
    background-repeat: no-repeat;
    width: 20px;
    cursor: pointer;
  }

  .title {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 25px;
  }

  // .k-avatar {
  //   display: block;
  //   width: 40px;
  //   height: 40px;
  //   flex-basis: 40px;
  //   margin: 0 25px;
  //   box-shadow: 0 0 0px 1px $white;
  // }

  .settings {
    display: none;
  }
}

@media (min-width: 480px) {
  .header {
    .title {
      flex-direction: row;
      align-items: center;
    }
    .vl {
      border-left: 1px solid $white;
      height: 25px;
      margin: 6px 15px;
    }
  }
}

@media (min-width: 768px) {
  .header {
    .settings {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
    }
  }
}

.loader-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
}

.sl-bubble5 {
  z-index: 9;
  }
// Uber Modal
div[data-baseweb="modal"] {
  z-index: 10008;
}

div[data-baseweb="popover"] {
  z-index: 10006;
  @media (max-width: 768px) {
    &.custom-popover {
      width:100%;
      cursor: none;
      
    }
    &.custom-left{
      width:100%;
      background-color: red;
      left:10%
    }
  }
 
}

.track_progress__table tbody tr:last-of-type td {
  border-bottom: none;
}

input::placeholder {
  font-family: "Manrope-Medium";
  color: rgba(163, 163, 163, 1);
}

// PDF Download
a[download="dolchecklist.pdf"] {
  color: rgb(65, 64, 123);
}

.cursor-pointer li{
   
      cursor: pointer;
    
}
.cursor-none li{

    cursor: 'not-allowed';

  
}
