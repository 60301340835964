.overview_loginSection {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  height: 100%;
  background-color: #1b246a;
}
.brandLogo {
  background-image: url("../../assets/images/login-logo.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 160px;
  min-height: 300px;
  box-sizing: border-box;
  margin: 20px auto;
}
.overview_loginSection_block {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  width: 60%;
  margin: auto;
  max-width: 800px;
  text-align: center;
  background-color: #2931b5;
  border-radius: 8px;
}
.overview_loginSection_forms {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 40px;
  box-sizing: border-box;
  text-align: left;
  font-size: 16px;
}
.link_with_up_line {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  display: block;
  padding: 15px 0 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  font-family: "Epilogue";
  margin: 0 !important;
}
.overview_loginSection_forms h1 {
  font-size: 30px;
  /* margin-bottom: 20px; */
  font-family: "Epilogue";
  font-weight: 600;
}
.overview_loginSection_forms label {
  display: block;
  margin: 15px 0 3px;
}
.overview_loginSection_forms input {
  width: 100%;
  border: 1px solid #cccccc !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  padding: 6px;
  background-color: #fff;
}
.overview_loginSection_forms a {
  display: block;
  color: #002ee3;
  margin: 0 auto 15px;
  cursor: pointer;
}
.submitBTN {
  display: block;
  border: none;
  padding: 9px 24px;
  background: #332ff1 !important;
  border-radius: 8px;
  margin: 20px 0px;
  width: 100%;
  color: #fff;
}
.k-form-buttons {
  margin-top: 0;
}
.forgot-pass {
  font-family: "Manrope-Medium";
  font-size: 16px;
  font-weight: 500;
}
.signup {
  font-family: "Epilogue";
  font-size: 20px;
  font-weight: 700;
}
.k-form .k-form-buttons {
  color: #fff;
}
.forgor-content {
  font-size: 16px;
  line-height: 1.5;
  font-family: "Manrope-Medium";
  font-weight: 500;
  float: left;
  margin: 0 0 10px;
}
.sign-flname {
  width: 45%;
  float: left;
}
.sign-flname:last-child {
  float: right;
}
.err-msg {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: red;
  padding-bottom: 10px;
}
.resetPasswordTooltip {
  display: flex;
}
.reserTooltip {
  width: 280px !important;
  background: #000000;
}
.resetInformationIcon {
  color: #000000;
  margin: 3px;
}
.overview_EmailVerification {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 5%);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 40px;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  display: flex;
  font-size: 16px;
}
.successClass {
  font-size: 20px;
  color: #00ff00;
  display: flex;
  line-height: 26px;
  font-family: "Epilogue";
}
.errorClass {
  font-size: 20px;
  color: #ff0000;
  display: flex;
  line-height: 26px;
  font-family: "Epilogue";
}
.submitBTN.ssohome{
  top:22%
}
